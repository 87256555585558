export function tabSwitch(el) {
  const list = el.childNodes;

  list.forEach((elem) => {
    elem.addEventListener("click", function () {
      list.forEach((elem) => {
        elem.classList.remove("active");
      });

      elem.classList.add("active");
    });
  });
}

export function setScroll(el) {
  let _childHeight = el.children[0].clientHeight;

  if (el.clientHeight < _childHeight) {
    el.style.overflowY = "scroll";
  }
}

export function showHiddenContent(
  showButton,
  showContent,
  saveContent,
  options
) {
  let layer = options.layer;

  showButton.addEventListener("click", function () {
    showContent.classList.add("active");
    saveContent.classList.add("active");
    document.querySelector(layer).classList.add("active");
  });
}

export function hideContent(saveContent, showContent, options) {
  let layer = options.layer;
  saveContent.addEventListener("click", function () {
    showContent.classList.remove("active");
    saveContent.classList.remove("active");
    document.querySelector(layer).classList.remove("active");
  });
}
